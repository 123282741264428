type Cookies = {
    [key: string]: string
}

function parseCookies(): Cookies {
    const cookies: string[] =
        typeof document !== 'undefined' ? document?.cookie?.split('; ') : []
    const cookieObj: Cookies = {}

    cookies.forEach((cookie) => {
        const [key, value] = cookie.split('=')
        cookieObj[decodeURIComponent(key)] = decodeURIComponent(value)
    })

    return cookieObj
}

export const getCookie = (key: string): string | undefined => {
    const cookies: Cookies = parseCookies()
    return cookies[key]
}

export const cookies: Cookies = parseCookies()
