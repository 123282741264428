import { useEffect, useState, FC, useMemo } from 'react'
import { usePathname } from 'next/navigation'
import { useStoreConfig } from '@pylot/config'
import { graphqlFetch } from '@pylot-data/graphqlFetch'
import { generateRiskifiedSessionId } from '@pylot-data/core-queries/riskified/generateRiskifiedSessionId'
import type { Cart } from '@pylot-data/pylotschema'
import { KeyedMutator } from 'swr/dist/types'
import { GraphQLError } from 'framework/pylot/graphqlFetch'
import { useCookies } from 'react-cookie'
import { getCookie } from '@components/common/RiskifiedBeacon/utils/getCookie'

const STORAGE_KEY = 'riskified_api'

type SessionIdOutput = {
    data_order_session_id: string
}
type SessionIdInput = {
    cart_id: string
}
type SessionIdOutputGql = {
    generateRiskifiedSessionId: SessionIdOutput
}

declare global {
    interface Window {
        RISKX: {
            go: (page: string) => void
        }
    }
}
type RiskifiedBeaconProps = {
    data?: {
        data: {
            cart: Cart
            isOptimistic?: boolean
        }
        errors?: GraphQLError[]
    }
    cartMutate: KeyedMutator<{
        data: { cart: Cart; isOptimistic?: boolean | undefined }
        errors?: GraphQLError[] | undefined
    }>
}

const RiskifiedBeacon: FC<RiskifiedBeaconProps> = ({
    data,
    cartMutate: mutate
}) => {
    const pathname = usePathname()
    const config = useStoreConfig()
    const gaStreamMeasurementID =
        config?.seo?.tagManager?.gtm?.gA4Config?.id ?? ''
    const [cookies] = useCookies(['_ga', `_ga_${gaStreamMeasurementID}`])

    const gaClientID = useMemo(() => {
        return (cookies._ga ?? '').substring(6).replaceAll('.', '')
    }, [cookies._ga])

    const gaStream = useMemo(() => {
        return (cookies?.[`_ga_${gaStreamMeasurementID}`] ?? '').split('.')
    }, [cookies, gaStreamMeasurementID])

    const {
        base: {
            riskified_beacon,
            customer_impersonation: { enabled: isInternalStore }
        }
    } = useStoreConfig()
    const [ga4ClientID, setGa4ClientID] = useState<string>(gaClientID)
    const [ga4Stream, setGa4Stream] = useState<string[]>(gaStream)

    const [sessionID, setSessionID] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const generateSessionId = async (generate: boolean) => {
        if (!generate) return false
        setIsLoading(true)
        const response = await graphqlFetch<SessionIdInput, SessionIdOutputGql>(
            {
                query: generateRiskifiedSessionId,
                variables: {
                    cart_id: data?.data?.cart?.id || ''
                }
            }
        )
        if (data?.data?.cart?.additionalInfo?.riskified_token === null) {
            await mutate(data, true)
        }

        handleSessionId(
            response?.data?.generateRiskifiedSessionId?.data_order_session_id
        )

        setIsLoading(false)
    }
    const addScript = (sessionID: string) => {
        const script = document.createElement('script')
        script.src = `${riskified_beacon?.beacon_url}${sessionID}` // Replace with the script URL you want to add
        script.id = 'riskified_beacon_script'
        script.async = true
        document.head.appendChild(script)
    }

    const removeScript = () => {
        const script = document.getElementById('riskified_beacon_script')
        if (script) {
            document.head.removeChild(script)
        }
    }
    const handleSessionId = (
        sessionId: string,
        clientID = ga4ClientID,
        stream = ga4Stream
    ) => {
        if (isInternalStore) {
            setSessionID(sessionId)
        } else {
            let gaSessionID = ''

            if (
                clientID &&
                stream &&
                stream?.filter(Boolean)?.length > 5 &&
                sessionID !== `${clientID}${stream[2]}`
            ) {
                gaSessionID = `${clientID}${stream[2]}`
                setSessionID(gaSessionID)
            }
        }
    }

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            (!sessionID ||
                (isInternalStore &&
                    sessionID &&
                    data?.data?.cart?.additionalInfo?.riskified_token !==
                        sessionID)) &&
            (data?.data?.cart?.id ||
                data?.data?.cart?.additionalInfo?.riskified_token)
        ) {
            let generate = true
            const localData = window.localStorage.getItem(STORAGE_KEY)
            const parsedData = localData === null ? {} : JSON.parse(localData)
            if (
                (parsedData?.cartId &&
                    sessionID ===
                        data?.data?.cart?.additionalInfo?.riskified_token) ||
                (data?.data?.cart?.additionalInfo?.riskified_token &&
                    (parsedData?.cartId === data?.data?.cart?.id ||
                        parsedData.sessionId !==
                            data?.data?.cart?.additionalInfo?.riskified_token))
            ) {
                handleSessionId(
                    data?.data?.cart?.additionalInfo?.riskified_token ??
                        parsedData.sessionID
                )
                generate = false
            }
            generateSessionId(generate)
        }
    }, [sessionID, data?.data?.cart?.id])

    useEffect(() => {
        if (sessionID !== '' && typeof window !== 'undefined') {
            window.localStorage.setItem(
                STORAGE_KEY,
                JSON.stringify({
                    cartId: data?.data?.cart?.id,
                    sessionID: sessionID
                })
            )
            removeScript()
            addScript(sessionID)
        }
    }, [sessionID])

    useEffect(() => {
        const gaStreamID = (
            getCookie(`_ga_${gaStreamMeasurementID}`) ?? ''
        ).split('.')
        const gaClientID = (getCookie('_ga') ?? '')
            .substring(6)
            .replaceAll('.', '')

        if (
            ga4ClientID !== gaClientID &&
            ga4Stream?.[2] !== gaStreamID?.[2] &&
            !isInternalStore &&
            typeof getCookie('_ga') == 'string'
        ) {
            setGa4ClientID(gaClientID)
            setGa4Stream(gaStreamID)
            handleSessionId('', gaClientID, gaStreamID)
        }
        sendPageView(pathname)
    }, [pathname])

    const sendPageView = (page: string) => {
        if (
            typeof window !== 'undefined' &&
            window.RISKX &&
            typeof window.RISKX.go === 'function'
        ) {
            window.RISKX.go(page)
        }
    }

    if (
        !riskified_beacon?.enabled ||
        !riskified_beacon?.beacon_url ||
        !sessionID ||
        isLoading
    ) {
        return null
    }

    return null
}

export default RiskifiedBeacon
